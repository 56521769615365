@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* NOTE: Custom type selector to disable tailwinds font-size for EditorJS */
h2 {
  font-size: 1.4em;
  font-weight: 500;
  margin: 0.67em 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Loading component spinner */
.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

/* NOTE: This <a /> style overwrite the anchor tag readOnly styles in the EditorJS library. 
This is to prevent the anchor tag from exceeding its container width and wrapping 
onto the next line. */

.cdx-block {
  display: inline-block; /* Display the anchor tag as a block-level element but inline within text flow */
  max-width: 100%; /* Set the maximum width of the anchor tag to prevent it from exceeding its container */
  word-wrap: break-word; /* Allow the content within the anchor tag to wrap onto the next line if it exceeds its width */
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* End Loading Component spinner */
